<template> 
    <b-card body-class="p-0" header-class="border-0"> 
      <template v-slot:header> 
        <b-row align-v="center"> 
   
          <b-col sm="12" md="3" lg="3"> 
            <h3 class="mb-0 header-text">Pay@ Reports</h3> 
          </b-col> 
   
           
          <b-col sm="12" lg="6" class="text-right col-center filter-selects"> 
            <b-row> 
              <b-col sm="12" lg="4" class="data-list"> 
                <!-- Search input --> 
                <b-form-input 
                  v-model="query" 
                  placeholder="Filter files" 
                  class="filter-input" 
                  v-bind:class="{ active: isActive, focused: isFocused }" 
                  @keyup="filterAllData" 
                  @focus="focused" 
                > 
                </b-form-input> 
              </b-col> 
              <b-col sm="12" lg="4"> 
                <el-date-picker 
                  v-model="formattedFromDate" 
                  type="datetime" 
                  @change="filterAllData" 
                  placeholder="from" 
                  class="input-center" 
                > 
                </el-date-picker> 
              </b-col> 
              <b-col sm="12" lg="4"> 
                <el-date-picker 
                  v-model="formattedToDate" 
                  type="datetime" 
                  @change="filterAllData" 
                  placeholder="until" 
                > 
                </el-date-picker> 
              </b-col> 
            </b-row> 
          </b-col> 
   
        </b-row> 
      </template> 
      <spinner v-if="isLoading"></spinner> 
   
      <!-- Table --> 
      <el-table 
        id="payAtReportsTable" 
        class="table-responsive table" 
        :data="displayData" 
        header-row-class-name="thead-light" 
        v-if="!isLoading" 
        @sort-change="sortChange" 
      > 
        <el-table-column 
          label="File name" 
          min-width="90px" 
          prop="fileName" 
          sortable 
        > 
          <template v-slot="{ row }"> 
            <div class="font-weight-600">{{ row.fileName }}</div> 
          </template> 
        </el-table-column> 
        <el-table-column 
          label="Date/Time" 
          min-width="90px" 
          :formatter="dateFormatter" 
          prop="dateDownloaded" 
          sortable 
        > 
        </el-table-column> 
        <el-table-column label="" min-width="35px"> 
          <template v-slot="{ row }"> 
            <div class="row-buttons"> 
              <button 
                v-if="row.cardTagHash != ''" 
                class="btn btn-sm btn-primary" 
                :data-id="row.Id" 
                @click="downloadFile( 
                  row.fileName, 
                  row.path 
                )" 
              > 
                <span class="button-text">Download</span> 
                <span class="button-icon"><i class="fas fa-unlink"></i></span> 
              </button> 
            </div> 
          </template> 
        </el-table-column> 
      </el-table> 
      <el-pagination 
        :current-page="currentPage" 
        @current-change="handleCurrentChange" 
        :total="rows" 
        :page-size="pageSize" 
        align="right" 
        v-if="!isLoading" 
      > 
      </el-pagination> 
    </b-card> 
  </template> 
<script> 
  import { 
    Table, 
    TableColumn, 
    DropdownMenu, 
    DropdownItem, 
    Dropdown, 
    DatePicker, 
    Pagination 
  } from "element-ui"; 
  import config from "../../config"; 
  import Spinner from "../../components/Spinner.vue"; 
  import {saveAs} from 'file-saver'; 
  import { DateTime } from "luxon"; 
  //import { axios } from "vue/types/umd"; 
  import axios from 'axios'; 
  export default { 
    name: "PayAtReport", 
    components: { 
      [Table.name]: Table, 
      [TableColumn.name]: TableColumn, 
      [Dropdown.name]: Dropdown, 
      [DropdownItem.name]: DropdownItem, 
      [DropdownMenu.name]: DropdownMenu, 
      [DatePicker.name]: DatePicker, 
      [Pagination.name]: Pagination, 
      Spinner 
    }, 
    data() { 
      return { 
        isActive: false, 
        isFocused: false, 
        currentPage: 1, 
        pageSize: 10, 
        isLoading: false, 
        businessName: "", 
        fileName: "", 
        path: "", 
        query: "", 
        formattedFromDate: "", 
        formattedToDate: "", 
        masterData: [], 
        tableData: [ 
          { 
            fileName: "Loading ....", 
            dateDownloaded: "2021-03-13T09:23:10.000Z" 
          } 
        ] 
      }; 
    }, 
    computed: { 
      rows() { 
        return this.tableData.length; 
      }, 
      displayData() { 
        if (!this.tableData || this.tableData.length === 0) return []; 
        return this.tableData.slice( 
          this.pageSize * this.currentPage - this.pageSize, 
          this.pageSize * this.currentPage 
        ); 
      } 
    }, 
    methods: { 
      sortChange($event) { 
        // Get sort change data 
        const { prop, order } = $event; 
        // Check for sort order and table data accordingly 
        if (order == "ascending") { 
          this.tableData = this.tableData.sort((a, b) => 
            a[prop] > b[prop] ? 1 : -1 
          ); 
        } 
   
        if (order == "descending") { 
          this.tableData = this.tableData.sort((a, b) => 
            a[prop] < b[prop] ? 1 : -1 
          ); 
        } 
      }, 
      filterAllData() { 
        // Get all files from store 
        const files = this.masterData; 
        let filterdFiles; 
   
        // Filter files by search query 
        if(this.formattedToDate == null || this.formattedToDate == "") { 
          filterdFiles = files.filter( 
            c => 
              c.fileName.toLowerCase().includes(this.query.toLowerCase()) && 
              new Date(c.dateDownloaded) >= this.formattedFromDate &&  
              new Date(c.dateDownloaded) <= DateTime.now().endOf('day').toJSDate() 
          ); 
        } else { 
          filterdFiles = files.filter( 
            c => 
              c.fileName.toLowerCase().includes(this.query.toLowerCase()) && 
              new Date(c.dateDownloaded) >= this.formattedFromDate &&  
              new Date(c.dateDownloaded) <= this.formattedToDate 
          ); 
        } 
        // Store the filtered data as the new tatble data values 
        this.tableData = filterdFiles; 
      }, 
      focused() { 
        // Set focused class on search input wrapper 
        this.isFocused = true; 
      }, 
      downloadFile(fileName, path) { 
        this.fileName = fileName; 
        this.path = path; 
   
        var http = this.axios.create({ 
        baseURL: config.apiUrl, 
        timeout: 30000, 
        headers: { Authorization: this.$store.getters["accounts/token"] } 
        }); 
        http({ 
          method: 'post', 
          url: "/payat-reports/file", 
          responseType: 'blob', 
          data: { fileName: this.fileName, path: this.path } 
        }).then(response => { 
          saveAs(response.data, fileName); 
        }).catch(err => { 
          this.tableData = []; 
          this.handleCurrentChange(1); 
        }); 
      }, 
      handleCurrentChange(val) { 
        this.currentPage = val; 
      }, 
      dateFormatter(row, column, cellValue) { 
        return this.formatDateISO(cellValue); 
      }, 
      formatDateISO(inputDate) { 
        var date = new Date(inputDate); 
        return ( 
          date.getFullYear() + 
          "-" + 
          ("0" + (date.getMonth() + 1)).slice(-2) + 
          "-" + 
          ("0" + date.getDate()).slice(-2) + 
          " " + 
          ("0" + date.getHours()).slice(-2) + 
          ":" + 
          ("0" + date.getMinutes()).slice(-2) + 
          ":" + 
          ("0" + date.getSeconds()).slice(-2) 
        ); 
      } 
    }, 
    mounted() { 
      this.isLoading = true; 
      var http = this.axios.create({ 
        baseURL: config.apiUrl, 
        timeout: 30000, 
        headers: { Authorization: this.$store.getters["accounts/token"] } 
      }); 
      http.get("/payat-reports/all").then(response => { 
        // Store retrieved files in table data 
        this.tableData = response.data; 
        this.masterData = response.data; 
        this.handleCurrentChange(1); 
        this.isLoading = false; 
      }).catch(err => { 
        console.log(err); 
        this.tableData = []; 
        this.handleCurrentChange(1); 
      }); 
    } 
  }; 
  </script> 
<style scoped> 
  #search-wrapper { 
    position: relative; 
    margin: 0; 
  } 
   
  .cta { 
    text-align: center; 
  } 
   
  #search-wrapper.focused input { 
    border-color: #f7941d; 
  } 
   
  #search-wrapper.active .clear-search { 
    display: flex; 
  } 
   
  #search-wrapper .clear-search { 
    padding-top: 2px; 
    position: absolute; 
    top: 50%; 
    right: 15px; 
    display: flex; 
    width: 20px; 
    height: 20px; 
    background: #f1f1f1; 
    justify-content: center; 
    align-items: center; 
    border-radius: 50%; 
    transform: translateY(-50%); 
    cursor: pointer; 
    display: none; 
  } 
  #search-wrapper .clear-search i { 
    font-size: 13px; 
    line-height: none; 
    padding: 0; 
    margin: 0; 
    color: #525f7f; 
  } 
   
  .devices { 
    padding: 0; 
    margin: 0; 
    margin-bottom: 20px; 
    list-style: none; 
    padding: 0 5px; 
    border-bottom: 1px solid #ebeef5; 
    margin-top: 15px; 
    display: inline-block; 
    width: 100%; 
    margin-bottom: 35px; 
  } 
   
  .devices .device { 
    display: flex; 
    justify-content: space-between; 
    border-top: 1px solid #ebeef5; 
    padding: 10px 0; 
  } 
   
  .devices .device button { 
    color: #fff; 
    background-color: #f1f1f1; 
    border-color: #f7941d; 
    outline: 0; 
    border: 0; 
    width: 30px; 
    height: 30px; 
    border-radius: 4px; 
  } 
   
  .devices .device button i { 
    color: #f7941d; 
  } 
   
  .message { 
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    align-items: center; 
    margin: 30px 0 50px; 
  } 
   
  .message-icon { 
    width: 70px; 
    height: 70px; 
    display: flex; 
    align-items: center; 
    justify-content: center; 
    background: #f5f5f5; 
    border-radius: 50%; 
    margin: 10px auto; 
    margin-bottom: 10px; 
  } 
   
  .message.delete { 
    margin-top: 10px; 
    margin-bottom: 10px; 
  } 
   
  .message.delete .message-icon { 
    background: #f5f5f5; 
  } 
   
  .message.delete .message-icon i { 
    color: #f7941d; 
    margin-top: -4px; 
  } 
   
  .message-icon i { 
    font-size: 30px; 
  } 
   
  .message span { 
    color: #525f7f; 
    font-size: 15px; 
    display: inline-block; 
    margin-bottom: 20px; 
  } 
   
  .success-message { 
    text-align: center; 
    margin: 0 auto; 
    margin-top: 10px; 
  } 
   
  .success-message p { 
    color: #f7941d; 
    margin-top: 15px; 
  } 
  .icon-wrapper { 
    width: 60px; 
    height: 60px; 
    background: #f7941d; 
    border-radius: 50%; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    margin: 0 auto; 
    margin-bottom: 5px; 
  } 
   
  .icon-wrapper i { 
    font-size: 30px; 
    color: #fff; 
  } 
  </style> 
  